.PasswordChange {

    h1 {
        margin-top: 0px;
    }
    label {
        margin-top: 20px;
        display: block;
    }
    button, input[type="password"] {
        width: 100%;
        max-width: 400px;
        margin-top: 20px;
        display: block;
        margin: 0px auto;
    }
    button {
        margin-top: 20px;
    }
    ul {
        list-style-position: inside;
        padding-left: 0px;
    }

    p.error, p.warn, p.ok {
        &::before {
            vertical-align: middle;
            height: 1em;
            width: auto;
            margin-right: 0.5em;


        }
    }
    p.error::before {
        content: url("../../res/error.svg");
    }
    p.warn::before {
        content: url("../../res/warn.svg");
    }
    p.ok::before {
        content: url("../../res/ok.svg");
    }
}